import { useParams } from '@remix-run/react';

import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../../components/ConfirmCancelModalContext';
import { useQueryParam } from '../../hooks/useQueryParam';
import { ImageViewer, MediaViewer, VideoViewer } from './MediaViewer';
export { MediaViewer, VideoViewer, ImageViewer };

function Media(): JSX.Element {
  const { mediaId } = useParams<'mediaId'>();
  const version = useQueryParam('version');
  return (
    <ConfirmCancelModalProvider>
      <div className='w-full h-full text-white flex items-center justify-center'>
        {mediaId ? (
          <MediaViewer
            media={mediaId}
            version={version}
            autoplay
            loadingStyle='global'
          />
        ) : (
          <div>Invalid Request</div>
        )}
      </div>

      <ConfirmCancelModalRoot />
    </ConfirmCancelModalProvider>
  );
}

// eslint-disable-next-line import/no-default-export
export default Media;
